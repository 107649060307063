<template>
  <div class="dashboard">
    <div class="dashboard__body">
      <div class="boxes">
        <router-link v-if="validModule('reportesFalla')" :to="{ name: 'IncidentsHome' }">
          <div class="text">
            <v-icon>mdi-list-status</v-icon>
            <div class="text__description">Reportes de Falla</div>
          </div>
        </router-link>
        <router-link v-if="validModule('inventario')" :to="{ name: 'InventoryHome' }">
          <div class="text">
            <v-icon>mdi-clipboard-list-outline</v-icon>
            <div class="text__description">Inventarios</div>
          </div>
        </router-link>
        <router-link v-if="validModule('permisosAplicacion')" :to="{ name: 'PermissionsHome' }">
          <div class="text">
            <v-icon>mdi-account-supervisor</v-icon>
            <div class="text__description">Permisos de Aplicación</div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Dashboard", // [SOLO] Dashboard a DashboardComponent
  methods: {
    checkModule({ moduleName = "", pageName = "" }) {
      let found = false;
      const activities = this.$store.state.auth.activities || [];
      if (Array.isArray(activities)) {
        found = activities.some((activity) => {
          return (
            activity.module_name == moduleName && activity.page_name == pageName
          );
        });
      }
      return found;
    },
    validModule(moduleName) {
      let found = false;
      const activities = this.$store.state.auth.activities;
      found = activities.some((activity) => {
        return activity.module_name == moduleName;
      });
      return found;
    },
  },
  created() {
    this.validModule("permisosAplicacion");
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/dashboardHome.scss";
</style>
